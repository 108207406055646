import { CompanyService } from './company.service';
import { DigitalDataService } from './digital-data.service';
import { EnterpriseHeadingService } from './enterprise-heading.service';
import { ServiceService } from './service.service';
import { ServicesFormsService } from './services-forms.service';
import { SettingsStorageService } from './settings-storage.service';

export const SERVICES = [
  CompanyService,
  DigitalDataService,
  EnterpriseHeadingService,
  ServiceService,
  ServicesFormsService,
  SettingsStorageService,
];

export { AdobeLaunchProviderService } from './adobe-launch-provider.service';
export { CompanyService } from './company.service';
export { DigitalDataService } from './digital-data.service';
export { EnterpriseHeadingService } from './enterprise-heading.service';
export { NewRelicProviderService } from './new-relic-provider.service';
export { ServiceService } from './service.service';
export { ServicesFormsService } from './services-forms.service';
export { SettingsStorageService } from './settings-storage.service';
export { TrackingService } from './tracking.service';
